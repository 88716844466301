/* You can add global styles to this file, and also import other style files */

.padding05 {
    padding: 0 8%;
}

.flexcenter {
    display: flex;
    align-items: center;
}

.oculto {
    display: none;
}